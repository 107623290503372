<template>
    <v-dialog transition="false" v-model="loading" fullscreen>
      <div class="loader-container">
        <div class="loader">
          <img src="@/assets/smsGroup/sms_icon.png" />
        </div>
      </div>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "Loader",
    props: {
      loading: {
        type: Boolean,
        required: true,
        default: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .loader {
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  