<template>
	<v-app id="app" ref="app" :key="$route.path">
		<v-main>
			<v-container fluid>
				<Branding />
				<v-bottom-navigation color="black" style="font-weight: bold" v-model="view" app v-if="showNavigation">
					<v-btn @click="navigateTo('building')" :value="navigationItems.building.view" height="100%">
						<span>{{ navigationItems.building.label }}</span>
						<v-icon :class="view === navigationItems.building.view ? 'icon-selected' : ''">{{
							navigationItems.building.icon }}</v-icon>
					</v-btn>
					<v-btn @click="navigateTo('bookings')" :value="navigationItems.bookings.view" height="100%">
						<span>{{ navigationItems.bookings.label }}</span>
						<v-icon :class="view === navigationItems.bookings.view ? 'icon-selected' : ''">{{
							navigationItems.bookings.icon }}</v-icon>
					</v-btn>
					<v-btn @click="navigateTo('food')" :value="navigationItems.food.view" height="100%"
						v-if="showRestaurantApp">
						<span>{{ navigationItems.food.label }}</span>
						<v-icon :class="view === navigationItems.food.view ? 'icon-selected' : ''">{{
							navigationItems.food.icon }}</v-icon>
					</v-btn>
					<v-btn @click="navigateTo('news')" :value="navigationItems.news.view" height="100%">
						<span>{{ navigationItems.news.label }}</span>
						<v-icon :class="view === navigationItems.news.view ? 'icon-selected' : ''">{{
							navigationItems.news.icon }}</v-icon>
					</v-btn>
					<!-- Help button: No view change, no selection -->
					<!-- <v-btn @click="openHelpDialog" height="100%">
						<span style="color: grey">{{ $t("menu.help") }}</span>
						<v-icon style="color: grey">mdi-help-circle-outline</v-icon>
					</v-btn> -->
					<v-btn @click="navigateTo('help')" :value="navigationItems.help.view" height="100%">
						<span>{{ navigationItems.help.label }}</span>
						<v-icon :class="view === navigationItems.help.view ? 'icon-selected' : ''">{{
							navigationItems.help.icon }}</v-icon>
					</v-btn>
					<v-btn @click="navigateTo('menu')" :value="navigationItems.menu.view" height="100%">
						<span>{{ navigationItems.menu.label }}</span>
						<v-icon :class="view === navigationItems.menu.view ? 'icon-selected' : ''">{{
							navigationItems.menu.icon }}</v-icon>
					</v-btn>
					<v-btn id="logout" @click="performLogout" height="100%">
						<span style="color: grey">{{ $t("menu.logout") }}</span>
						<v-icon color="grey">mdi-logout</v-icon>
					</v-btn>
				</v-bottom-navigation>

				<v-slide-x-transition mode="out-in">
					<router-view />
				</v-slide-x-transition>
				<!-- Help Dialog -->
				<!-- <VideoGuideDialog v-model="showHelpDialog" /> -->
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Branding from "@/customer-branding/Branding.vue";
import VideoGuideDialog from "@/components/Child_Components/VideoGuideDialog.vue"; // Import VideoGuideDialog
import { OAuthService } from "@/services/OAuthService";
import Vue from "vue";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
	name: "App",
	components: { Branding, VideoGuideDialog }, // Register VideoGuideDialog
	data() {
		return {
			view: "",
			showHelpDialog: false, // Dialog visibility state
			navigationItems: {
				building: {
					view: "building",
					label: this.$t("office.office"),
					icon: "mdi-office-building-marker-outline",
					route: "building",
				},
				bookings: {
					view: "bookings",
					label: this.$t("booking.bookings"),
					icon: "mdi-calendar-month-outline",
					route: "bookings",
				},
				food: {
					view: "food",
					label: this.$t("food.food"),
					icon: "mdi-food",
					route: "restaurantApp",
				},
				news: {
					view: "news",
					label: "Info",
					icon: "mdi-newspaper-variant-outline",
					route: "infoCenter",
				},
				help: {
					view: "help",
					label: this.$t("menu.help"),
					icon: "mdi-help-circle-outline",
					route: "help",
				},
				menu: {
					view: "menu",
					label: this.$t("menu.menu"),
					icon: "mdi-menu",
					route: "menu",
				},
			},
		};
	},
	computed: {
		showNavigation() {
			const restrictedRoutes = ["no-permission", "login", "/"];
			return !restrictedRoutes.includes(this.$route.name) && !restrictedRoutes.includes(this.$route.fullPath);
		},
		showRestaurantApp() {
			let qaUrl = "sms.qa.pinestack.eu";
			let stagingUrl = "sms.staging.pinestack.eu";
			let localUrl = "localhost:8080";
			let currentUrl = window.location.origin;
			if (currentUrl.includes(localUrl) || currentUrl.includes(stagingUrl) || currentUrl.includes(qaUrl)) {
				return true;
			}
			return false;
		},
	},
	methods: {
		syncViewWithRoute() {
			const route = this.$route.name;
			for (const key in this.navigationItems) {
				if (this.navigationItems[key].route === route) {
					this.view = this.navigationItems[key].view;
					break;
				}
			}
		},
		async performLogout() {
			Swal.fire({
				icon: "warning",
				title: this.$t("messages.title-logout"),
				text: this.$t("messages.text-are-you-sure-you-want-to-logout-"),
				allowOutsideClick: true,
				confirmButtonColor: "red",
				showCancelButton: true,
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel",
			}).then(async result => {
				if (result.isConfirmed) {
					try {
						const { doLogout } = await import("@/controllers/BackboneController");
						doLogout();
					} catch (error) {
						console.error("Logout error:", error);
					}
				}
			});
		},
		navigateTo(view) {
			// Only change the view if it's not 'help'
			//if (view !== "help" && this.navigationItems[view]) {
			this.view = this.navigationItems[view].view;
			this.$router.replace({ name: this.navigationItems[view].route }).catch(error => {
				console.error("Navigation error:", error);
			});
			//}
		},
		// Open Help Dialog without changing view
		openHelpDialog() {
			this.showHelpDialog = true;
		},
	},
	watch: {
		"$route"(newRoute) {
			for (const key in this.navigationItems) {
				if (this.navigationItems[key].route === newRoute.name) {
					this.view = this.navigationItems[key].view;
					console.log("Matched Nav Item:", this.navigationItems[key]);
					break;
				}
			}
		},
		view: {
			deep: true,
			immediate: true,
			handler() {
				this.syncViewWithRoute();
			},
		},
	},
};
</script>
<style scoped>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

.v-bottom-navigation {
	padding: 0 !important;
	margin: 0 !important;
	height: 56px;
	/* Adjust the height if necessary */
	display: flex;
	justify-content: center;

	@media (max-width: 450px) {
		height: 48px;

		.v-btn {
			min-width: 45px;
			padding: 0 6px;
		}

		span {
			font-size: 10px;
			/* Adjust font size */
		}

		v-icon {
			font-size: 16px;
			/* Adjust icon size */
		}
	}
}

.icon-selected {
	color: red !important;
	/* Color for selected icons */
}
</style>
