
import Axios					from "axios";
import { OAuthService }			from "@/services/OAuthService.js";



const ApiFactoryClass = class ApiFactory {

	constructor() {

		this.promise = new Promise((resolve, reject) => {
			this.resolvePromise = resolve;
		});
		this.initialized = false;

	}

	initialize() {
		this.apiInfos = {};
		this.axiosInstances = {};

		return OAuthService.logIn().then((token) => {

			this.token = token;
			this.initialized = true;

			this.resolvePromise();

		}).catch((e) => {
			console.log(e)
		});
	}

	isInitialized() {
		return this.initialized;
	}

	getPromise() {
		return this.promise;
	}

}

class Api {

	constructor(axios) {
		this.axios = axios;
	}


	custom(requestObject) {
		return new Promise((resolve, reject) => {
			this.axios(requestObject).then((response) => {
				resolve(response);
			}, (error) => {
				reject(error);
			});
		});
	}

	get(url, params = {}) {

		return new Promise((resolve, reject) => {

			this.axios.get(url, {params: params}).then((response) => {

				if (response && response.data) {
					resolve(response.data);
				}

			}, (error) => {
				reject(error);
			});

		});

	}

	post(url, data) {

		return new Promise((resolve, reject) => {

			this.axios.post(url, data).then((response) => {

				if (response && response.status >= 200 && response.status < 300) {
					resolve(response.data);
				} else {
					reject(response);
				}

			}, (error) => {
				reject(error);
			});

		});

	}

	delete(url, data) {
		return new Promise((resolve, reject) => {

			this.axios.delete(
				url,
				{
					data: data,
					headers: {
						"Content-Type": "application/json"
					}
				}
			).then((response) => {

				if (response && response.status >= 200 && response.status < 300) {
					resolve();
				} else {
					reject();
				}

			}, (error) => {
				reject(error);
			});

		});

	}

	put(url, data) {

		return new Promise((resolve, reject) => {

			this.axios.put(url, data, {
				headers: {
					"Content-Type": "application/json"
				}
			}).then((response) => {

				if (response && response.status >= 200 && response.status < 300) {

					resolve(response.data);

				} else {
					reject();
				}

			}, (error) => {
				reject(error);
			});

		});

	}

}

const ApiFactory = new ApiFactoryClass();
export { ApiFactory };
