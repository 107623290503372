import router from "@/router/index.js";

import { ApiFactory } from "@/services/API.js";
import { OAuthService } from "@/services/OAuthService.js";
import AuthCallback from "@/views/auth/AuthCallback.vue";
import LoggedOut from "@/views/auth/LoggedOut.vue";
import Unauthorized from "@/views/auth/Unauthorized.vue";
import NoPermissionView from "@/views/NoPermissionView.vue";
import Gdpr from "@/components/Gdpr.vue";

class AuthRoutesService {
	constructor() {
		this.authroutes = [];
	}

	initialize() {
		router.addRoute({ path: "/oauth2/code/callback", name: "auth-callback", component: AuthCallback, meta: { authRoute: true } });
		router.addRoute({ path: "/unauthorized-email", name: "unauthorized", component: Unauthorized, meta: { authRoute: true } });
		router.addRoute({ path: "/logged-out", name: "logged-out", component: LoggedOut, meta: { authRoute: true } });
		router.addRoute({ path: "/no-permission", name: "no-permission", component: NoPermissionView, meta: { authRoute: true } });
		router.addRoute({ path: "/buildings", name: "building", component: () => import("../components/Building.vue"), meta: { authRoute: true } });
		router.addRoute({ path: "/infoCenter", name: "infoCenter", component: () => import("../components/InfoCenter.vue"), meta: { authRoute: true } });
		router.addRoute({ path: "/", name: "HomeView", component: () => import("../views/HomeView.vue"), meta: { authRoute: true } });

		router.beforeEach(async (to, from, next) => {
			try {
				console.log("Begin route guard.");

				// Excluding the no-permission route from login requirement
				

				let logInRequired = true;

				if (to && to.meta && to.meta.authRoute) {
					logInRequired = false;
				}

				if (logInRequired) {
					console.log("Login required.");
					await OAuthService.checkExpiration();

					if (!OAuthService.isLoggedIn()) {
						console.log("User is not logged in.");
						let authUrl;
						if (to && to.fullPath) {
							authUrl = to.fullPath;
						}
						await OAuthService.logIn(authUrl);
						console.log("Completed login operation.");
						return next(false);
					} else {
						console.log("User is logged in.");
						if (!ApiFactory.isInitialized()) {
							console.log("API Factory is not initialized.");
							await ApiFactory.initialize();
						}

						// Dynamically import the getUserProfile function
						const userProfile = OAuthService.getUserProfile();					
						let hasDepartment = userProfile && userProfile.department !== undefined && userProfile.department !== "";
						let isPermissionPage = to.name === "no-permission";
						let isBuildingPage = to.name === "building";
						let isGlobalAdmin = userProfile && userProfile.realm_access && userProfile.realm_access.roles.includes('globaladmin')
						if ( !hasDepartment && !isPermissionPage && !isBuildingPage && isGlobalAdmin === false) {
							// Redirecting to no-permission if department doesn't exist or is empty
							return next({ name: "no-permission" });
						}

						return next();
					}
				} else {
					console.log("Login not required.");
					return next();
				}
			} catch (error) {
				console.error("Error in route guard:", error);
				return next();
			}
		});
	}
}

let instance = new AuthRoutesService();

export { instance as AuthRoutesService };
