<template>
	<v-dialog transition="false" v-model="isLoading" fullscreen>
		<div class="loader-container">
			<!-- Loader for the SMS group -->
			<div class="image-loader">
				<img src="@/assets/smsGroup/sms_icon.png">
			</div>
		</div>
	</v-dialog>
</template>

<script>
	export default {
		name: "Loader",
		data: () => ({
			IS_SMS_GROUP: false,
			isLoading: true, // Changed from computed to data
		}),
		props: {
			loading: {
				type: Boolean,
				required: true,
				default: true,
			},
		},
		created() {
			this.isLoading = this.loading; // Initialize isLoading with the value of the prop
			let customerName = this.$store.getters.getUserGroupName;
			if (customerName?.toLowerCase().includes("sms")) {
				this.IS_SMS_GROUP = true;
			}
		},
		watch: {
			loading(newVal) { // Update isLoading whenever the prop changes
				this.isLoading = newVal;
			}
		}
	};
</script>

<style scoped>
	.loader-container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.8);
	}

	.image-loader img {
		width: 50px;
		height: 50px;
		animation: spin 2s linear infinite;
	}

	.loader {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}

	.loader div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid #000;
		border-radius: 50%;
		animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #000 transparent transparent transparent;
	}

	.loader div:nth-child(1) {
		animation-delay: -0.45s;
	}

	.loader div:nth-child(2) {
		animation-delay: -0.3s;
	}

	@keyframes loader {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
