<template>
	<div class="branding"></div>
</template>

<script>
    import smsLoader from '@/customer-branding/Loaders/sms-loader.vue';
	import Loader from "@/components/Loader.vue";
	export default {
		name: "branding",
		components: {  },
		data: () => ({
			primaryColor: "black",
			brandLogo: undefined,
			IS_SMS_GROUP:false,
		}),
		created() {
			let customerName = this.$store.getters.getUserGroupName;
			if (customerName?.toLowerCase().includes("sms")) {
				this.primaryColor = "red";
				this.IS_SMS_GROUP = true;
			}
		},
		mounted() {
			this.changeNavBarColor();
			if(this.IS_SMS_GROUP){
				this.changeBrandLogo();
			}
			
		},
		methods: {
			changeNavBarColor() {
				let activeIcons = document.querySelectorAll(".v-btn.v-btn--active .v-icon");
				let activeTexts = document.querySelectorAll(".v-btn.v-btn--active .v-btn__content");

				activeIcons.forEach(icon => {
					icon.style.color = this.primaryColor; // Change the color to red for active icons
				});

				activeTexts.forEach(text => {
					text.style.color = this.primaryColor; // Change the color to red for active text
					text.style.fontStyle = "italic"; // Make the active text italic
				});
			},
			changeBrandLogo() {
				// Gets all elements with the given class name
				let elements = document.getElementsByClassName("v-toolbar__content");

				// Iterates over the elements
				for (let i = 0; i < elements.length; i++) {
					// Gets all img tags within the current element
					let imgTags = elements[i].getElementsByTagName("img");

					// Iterates over the img tags
					for (let j = 0; j < imgTags.length; j++) {
						
						// Changes the src attribute of the img tag
						imgTags[j].src =  require('@/assets/smsGroup/sms_white_logo.svg')
						imgTags[j].style.height = "60px";
					}
				}
			},
			
		},
	};
</script>

<style scoped></style>
