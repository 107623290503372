<template>

	<div class="gdpr">

		<h1>GDPR</h1>

	</div>

</template>

<script>


	export default {
		name: "gdpr",
		components: {
			//DefaultDialog,
		},
		data() {
			return {
			};
		},
		methods: {
			// accept() {
			// 	GdprService.termsAccepted();
			// },
			// decline() {
			// 	this.$refs.dialog.open();
			// }
		},
		created() {
		},
	}

</script>

<style lang="scss">

	.gdpr {
		width: 100%;
		height: 100%;
		overflow: scroll;

	}

</style>
