<template>
	<transition name="dialog-fade">
		<div v-if="value" class="custom-dialog-overlay">
			<div class="custom-dialog">
				<div class="dialog-content">
					<video width="100%" controls>
						<source :src="videoSrc" type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
				<div class="dialog-actions">
					<v-btn :disabled="loading" class="close-btn ma-1" depressed @click="closeDialog">
						{{ closeLabel }}
					</v-btn>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, default: false },
		closeLabel: {
			type: String,
			default: "Close",
		},
		videoSrc: {
			type: String,
			required: true,
			default: "",
		},
	},
	data() {
		return {
			loading: false,
		};
	},
	methods: {
		closeDialog() {
			this.$emit("input", false);
		},
	},
};
</script>

<style scoped>
.custom-dialog-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
}

.custom-dialog {
	background-color: rgba(0, 0, 0, 0.7);
	color: #333;
	border-radius: 12px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	width: 90%;
	max-width: 650px;
}

.dialog-content {
	padding: 0;
	margin: 0;
	text-align: center;
}

.dialog-actions {
	padding: 15px;
	text-align: center;
	background: rgba(0, 0, 0, 0.7);
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
	border-radius: 0 0 12px 12px;
}

/* Custom close button styles */
.close-btn {
	border: none;
	background: linear-gradient(145deg, #b71c1c, #f44336);
	color: white !important;
	padding: 10px 20px;
	border-radius: 8px;
	cursor: pointer;
	font-size: 16px;
	transition: background 0.3s, transform 0.3s;
}

/* Hover effect for the close button */
.close-btn:hover {
	background: linear-gradient(145deg, #d32f2f, #e57373);
	transform: scale(1.05);
}

.dialog-fade-enter-active,
.dialog-fade-leave-active {
	transition: opacity 0.5s;
}

.dialog-fade-enter,
.dialog-fade-leave-to {
	opacity: 0;
}

/* Responsiveness */
@media (max-width: 768px) {
	.custom-dialog {
		width: 95%;
		max-width: 500px;
	}
}

@media (max-width: 480px) {
	.custom-dialog {
		width: 90%;
		border-radius: 8px;
	}

	.close-btn {
		width: 60%;
		font-size: 14px;
		padding: 12px;
	}
}
</style>
