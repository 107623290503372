<template>

	<div class="auth-callback">
		<!-- <img class="loading-image" src="/static/assets/img/loading-indicator.gif" alt="loading indicator"> -->
	</div>

</template>

<script>

	import { OAuthService } from '@/services/OAuthService';

	export default {
		name: "code-auth-callback",
		data() {
			return {};
		},
		created() {
			OAuthService.callback();
		}
	};

</script>

<style lang="scss">

	.auth-callback {
		height: 100vh;
		width: 100vw;
		position: relative;

		.loading-image {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
		}
	}

</style>
