<template>
    <v-container style="height: 100vh" fluid class="no-permission-page fill-height">
        <v-row class="fill-height" justify="center" align="center">
            <v-col cols="12" sm="8" md="8" lg="8">
                <v-card dark class="elevation-12 no-permission-card mx-auto">
                    <v-toolbar dark color="red">
                        <v-toolbar-title>
                            <v-icon large class="mr-3 white--text">mdi-alert-circle-outline</v-icon>
                            {{ $t("messages.permission") }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text class="pt-4 text-center white--text">
                        <div class="display-1 font-weight-thin mb-4"> {{ $t("messages.opps") }}</div>
                        <div class="title font-weight-light mb-2"> {{ $t("messages.no-permission") }}</div>               
                        <div class="caption font-weight-light"> {{ $t("messages.contact-admin") }}</div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        
                        <v-btn large color="primary" dark @click="logout">
                            <v-icon left>mdi-logout</v-icon>
                            {{ $t("menu.logout") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>



<script>
	export default {
		name: "no-permission",
		data() {
			return {
			};
		},
		created() {
		},
		methods: {
			async logout() {
				let { doLogout } = await import("@/controllers/BackboneController.js");
				doLogout();
			},
           
		},
	};
</script>

<style scoped>
	.no-permission-page {
		background: #000;
	}

	.no-permission-card {
		background: #222;
		border-radius: 10px;
	}

	.select-department .v-select__slot {
		color: white !important;
	}

	.select-department .v-input__slot::before {
		border-color: white !important;
	}

	.select-department .v-input__slot::after {
		border-color: white !important;
	}

	.select-department .v-select__selections {
		color: white !important;
	}

	.select-department input {
		color: white !important;
	}
</style>
