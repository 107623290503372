import translations from "@/translation/translations.js";
import Vue from "vue";
import VueI18n from "vue-i18n";
import VueCookies from 'vue-cookies';
import moment from "moment";
import "moment/locale/en-gb";

Vue.use(VueI18n);
Vue.use(VueCookies);

const STORAGE_KEY = "appLanguage";

function getBrowserLanguage() {
    const browserLang = navigator.language || navigator.userLanguage;
    return ["de", "en"].includes(browserLang.substr(0, 2)) ? browserLang.substr(0, 2) : "en";
}

function getDefaultLanguage() {
    const storedLang = Vue.$cookies.get(STORAGE_KEY);
    return (storedLang && ["de", "en"].includes(storedLang)) ? storedLang : getBrowserLanguage();
}

const i18n = new VueI18n({
    locale: getDefaultLanguage(),
    messages: translations,
});

moment.locale(i18n.locale);

let translateMethod;
let translatePluralMethod;

const data = [
    {
        locale: "en-gb",
        code: "en",
        name: { de: "Englisch", en: "English" },
    },
    {
        locale: "de-de",
        code: "de",
        name: { de: "Deutsch", en: "German" },
    },
];

const TranslationService = {
    data: data,
    dataByCode: {},

    translate(text, placeholders) {
        return translateMethod(text, placeholders);
    },

    translatePlural(text, count) {
        return translatePluralMethod(text, count);
    },

    setLanguage(code) {
        if (!this.dataByCode[code]) {
            console.error(`Language code ${code} does not exist in data.`);
            return;
        }

        this.language = code;
        this.currentLanguage = code;
        i18n.locale = code;
        moment.locale(this.dataByCode[code].locale);

        Vue.$cookies.set(STORAGE_KEY, code, "1d");

        const htmlTag = document.getElementsByTagName("html")[0];
        if (htmlTag) {
            htmlTag.setAttribute("lang", code);
        } else {
            console.warn("Couldn't find <html> tag to set 'lang' attribute.");
        }

        const contentLanguageMetaTag = document.querySelector('[http-equiv="content-language"]');
        if (contentLanguageMetaTag) {
            contentLanguageMetaTag.setAttribute("content", code);
        } else {
            console.warn("Couldn't find meta tag with '[http-equiv=\"content-language\"]' to set 'content' attribute.");
        }
    },

    initialize(vueInstance) {
        translateMethod = vueInstance.$t.bind(vueInstance);
        translatePluralMethod = vueInstance.$tc.bind(vueInstance);

        for (let lang of data) {
            this.dataByCode[lang.code] = lang;
        }

        const storedLang = Vue.$cookies.get(STORAGE_KEY);
        const validLanguageCodes = data.map(lang => lang.code);
        let langToSet;

        if (storedLang && validLanguageCodes.includes(storedLang)) {
            langToSet = storedLang;
        } else {
            const systemLang = (navigator.language || navigator.userLanguage).substring(0,2);
            langToSet = validLanguageCodes.includes(systemLang) ? systemLang : getDefaultLanguage();
        }

        if (!validLanguageCodes.includes(langToSet)) {
            langToSet = "en";
        }

        this.setLanguage(langToSet);
    },

    getLanguage() {
        return this.language;
    },
};

export { i18n, TranslationService };
export default TranslationService;
