import ClientOAuth2 from "client-oauth2";
import moment from "moment";
import router from "@/router";
import HomeView from "@/views/HomeView.vue";
import store from "@/store"; // update this path to your actual store path

const TOKEN_KEY = "pinestack.token";
const PRE_AUTH_URL_KEY = "pinestack.pre-auth-url";
let checkLoginEmails = false;

const path = location.protocol + "//" + location.host;
var config = {
	clientId: configs.VUE_APP_CLIENT_ID,
	redirectUri: path + "/oauth2/code/callback",
	authorizationUri: configs.VUE_APP_AUTHORIZATION_URI,
	accessTokenUri: configs.VUE_APP_ACCESS_TOKEN_URI,
	scopes: ["openid", "profile", "email"],
	query: {
		response_type: "token",
		nonce: new Date().getTime(),
	},
};

let lib = new ClientOAuth2(config);

function parseJwt(token) {
	if (!token) {
		console.log("Token is not valid.");
		return null;
	}

	try {
		var base64Url = token.split(".")[1];
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		var jsonPayload = decodeURIComponent(
			atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);

		return JSON.parse(jsonPayload);
	} catch (e) {
		console.error("Error while parsing token: ", e);
		return null;
	}
}

class OAuthService {
	constructor() {
		this.token = sessionStorage[TOKEN_KEY];
	}

	async logIn(url) {
		if (this.isLoggedIn()) {
			let currentUserObject = this.getUserProfile();
			const departmentFullString = currentUserObject["department"];
			if (departmentFullString?.includes("(")) {
				const extractedValue = departmentFullString.split(" ")[0]; // Assuming the part before the parentheses is always the first word
				currentUserObject["department"] = extractedValue;
			}

			// For Testing //
			//currentUserObject.realm_access.roles.splice(currentUserObject.realm_access.roles.indexOf('globaladmin'), 1);
			//delete currentUserObject["department"]
			//************ */
			store.commit("SET_USER_PROFILE", currentUserObject);
			let buildingFromStorage = localStorage.getItem("selectedBuilding");
			if (!buildingFromStorage) {
				try {
					const { getBuildings } = await import("@/controllers/BaseController");
					const buildings = await getBuildings();
					if (buildings?.length !== 0) {
						localStorage.setItem("selectedBuilding", JSON.stringify(buildings[0]));
					}
				} catch (e) {
					console.error("Error parsing building from local storage:", e);
				}
			}
			return Promise.resolve(this.token);
		} else {
			let preAuthUrl = url;
			if (!preAuthUrl) {
				preAuthUrl = window.location.pathname + window.location.hash;
			}
			sessionStorage[PRE_AUTH_URL_KEY] = preAuthUrl;
			window.location = lib.token.getUri();

			return new Promise((resolve, reject) => {});
		}
	}

	callback() {
		let uri = window.location.href;

		lib.token
			.getToken(uri)
			.then(user => {
				// for open id connect:
				// this.token = user.data.id_token;
				// for token auth
				this.setToken(user.accessToken);

				//let userObject = this.getUserProfile();
				let loginEmailFound = false;

				if (checkLoginEmails && !loginEmailFound) {
					this.signOut(false);
					if (window.pinestack.WFE.INVALID_EMAIL_REDIRECT) {
						window.location = window.pinestack.WFE.INVALID_EMAIL_REDIRECT;
					} else {
						router.push({ name: "unauthorized" });
					}
				} else if (!sessionStorage[PRE_AUTH_URL_KEY]) {
					router.push("/");
				} else {
					let url = sessionStorage[PRE_AUTH_URL_KEY];

					router.push(url);
				}
			})
			.catch(e => {
				console.error(e);
				//router.push({ name: "unauthorized" });
			})
			.finally(() => {});
	}

	isLoggedIn() {
		let isLoggedIn = !!this.token;
		this.checkExpiration();
		return isLoggedIn;
	}

	signOut(redirect) {
		this.token = null;
		delete sessionStorage[TOKEN_KEY];
		if (redirect !== false) {
			router.push({ name: "logged-out" });
			if (this.preferOfflineToken()) {
				// only completely sign out the user (with clearing the tokens) if the user also sees the "logged out screen"
				// this way we ensure that the long-running refresh token is only cleared when the user actually intented to throw the user out
				// as apposed to "signing the user out" before attempting to refresh the token
				OfflineAuthService.clearTokens();
				this.resetUsingOfflineToken();
			}
		}
	}

	logOut() {
		this.token = null;
		delete sessionStorage[TOKEN_KEY];
		sessionStorage.removeItem("retryLogout");
		router.push({ name: HomeView });
		sessionStorage.clear();
		localStorage.clear();
	}

	getUserProfile() {
		let user = {};
		if (this.token) {
			let jwt = {
				payload: parseJwt(this.token),
			};

			user = { ...jwt.payload }; // Copies all properties from the JWT payload into the user object

			// Additional processing if required
			user.name = user.displayName || user.name;
			if (user.name && user.name.indexOf(",") > 0) {
				user.name = user.name.replace(",", "");
			}
			user.email = user.email ?? user.upn;
		}
		const storedTestingEnabled = JSON.parse(sessionStorage.getItem("testingEnabled"));
		if (storedTestingEnabled) {
			const department = JSON.parse(sessionStorage.getItem("testingKeyValues"))["department"];
			user["department"] = department;
		}

		return user;
	}

	checkExpiration(withTimeframe) {
		if (this.token) {
			let jwt = { payload: parseJwt(this.token) };
			let ttl = jwt.payload.exp - moment().unix();
			let timeFrame = 60 * 10;

			if (withTimeframe === false) {
				timeFrame = 0;
			}

			if (ttl < timeFrame) {
				this.signOut(false);
				this.logIn(); // Reauthentication after expiry
			}
		}
	}

	getToken() {
		return this.token;
	}

	getParsedToken() {
		if (!this.token) {
			return undefined;
		} else {
			return parseJwt(this.token);
		}
	}

	setToken(token) {
		this.token = token;
		sessionStorage[TOKEN_KEY] = this.token;
	}
}

let instance = new OAuthService();

export { instance as OAuthService };
